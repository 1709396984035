import React, {
  createContext,
  useContext,
  Dispatch,
  useReducer,
  useState
} from 'react';
import { PageContext } from '../types'

const initialState: PageContext = {}

export type SiteGlobals = {
  pageContext?: PageContext,
  setSiteConfig?: Dispatch<SiteActions>
  contactVisible: boolean;
  setContactVisible: Dispatch<boolean>;
  showChatBox: boolean;
  setShowChatBox: Dispatch<boolean>;
}

type SiteActions = { type: 'SET', payload: PageContext }

function reducer(state: PageContext, action: SiteActions) {
  if (action.type === 'SET') {
    return {
      ...state,
      ...action.payload
    }
  }
  return state;
}

const SiteContext = createContext<SiteGlobals>({});

const SiteProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [contactVisible, setContactVisible] = useState(false);
  const [showChatBox, setShowChatBox] = useState(false);
 
  return (
    <SiteContext.Provider value={{ 
      pageContext: state, 
      setSiteConfig: dispatch, 
      contactVisible,
      setContactVisible,
      showChatBox,
      setShowChatBox 
      }}>
      {children}
    </SiteContext.Provider>
  )
}

const useGlobalStateContext = () => {
  return useContext(SiteContext);
}

export { useGlobalStateContext, SiteProvider };
